import React from 'react'
import styled from 'styled-components'
import { media } from '../utils/style-utils'

const Bar = styled.div`
  position: absolute;
  top: 120px;
  width: 100%;
  padding-bottom: 56.25%;
  z-index: 100;
  overflow: hidden;
  display: none;

  ${media.large`
    top: 100px;
    height: calc(100vh - 100px);
    padding: 0;
    display: ${(props) => (props.displayNone ? 'none' : 'block')};

  `};

  ${media.xlarge`

    height: calc(100vh - 160px);
    padding: 0;
    top: 120px;
  `};

  ${media.xxlarge`
    top: 160px;
  `};

  ${media.xxxlarge`
    height: 1080px;
    padding: 0;
  `};

  @media (min-device-width: 1024px) and (max-device-width: 1024px) and (min-device-height: 1366px) and (max-device-height: 1366px) and (min-width: 1024px) and (max-width: 1024px) {
    padding-bottom: 56.25%;
    height: auto;
    top: 120px;
  }

  @media (min-device-width: 1366px) and (max-device-width: 1366px) and (min-device-height: 1024px) and (max-device-height: 1024px) and (min-width: 1366px) and (max-width: 1366px) {
    padding-bottom: 56.25%;
    height: auto;
    top: 120px;
  }

  @media (min-width: 1024px) and (max-height: 900px) {
    top: 100px;
    height: calc(100vh - 100px);
    padding: 0;
  }
`

const BarAni = styled.div`
  position: absolute;
  background: ${(props) => props.theme.purple};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: ${(props) =>
    (props.barAnimate && 'translateY(calc(2px - 100%)) translateX(0)') ||
    (props.barAnimateStep2 && 'translateY(0) translateX(0) !important') ||
    'translateY(calc(2px - 100%)) translateX(calc(0px - 100%))'};
  transition: transform 700ms cubic-bezier(0.215, 0.61, 0.355, 1),
    opacity 300ms linear;
  opacity: ${(props) => (props.hideBar ? 0 : 1)};
`

class Intro extends React.Component {
  _timer1
  _timer2
  _timer3
  _timer4
  _timer5

  constructor() {
    super()

    this.state = {
      barAnimate: false,
      barAnimateStep2: false,
      hideBar: false,
      displayNone: false,
    }
  }

  componentDidMount() {
    const aniDelay = window.animationPlayed ? 0 : 1400

    this._timer1 = setTimeout(() => {
      this.setState({ barAnimate: true })
    }, aniDelay)

    this._timer2 = setTimeout(() => {
      this.setState({ barAnimateStep2: true, barAnimate: false })
    }, aniDelay + 1000)

    this._timer3 = setTimeout(() => {
      let evt = new CustomEvent('playVideo')
      document.dispatchEvent(evt)
    }, aniDelay + 1000)

    this._timer4 = setTimeout(() => {
      this.setState({ hideBar: true })

      let evt = new CustomEvent('showHomeHeader')
      document.dispatchEvent(evt)

      this._timer5 = setTimeout(() => {
        this.setState({
          displayNone: true,
        })
      }, 300)
    }, aniDelay + 1800)

    window.animationPlayed = true
  }

  componentWillUnmount() {
    clearTimeout(this._timer1)
    clearTimeout(this._timer2)
    clearTimeout(this._timer3)
    clearTimeout(this._timer4)
    clearTimeout(this._timer5)
  }

  render() {
    return (
      <Bar displayNone={this.state.displayNone}>
        <BarAni
          barAnimate={this.state.barAnimate}
          barAnimateStep2={this.state.barAnimateStep2}
          hideBar={this.state.hideBar}
        />
      </Bar>
    )
  }
}

export default Intro
