import React from 'react'
import { Link } from 'gatsby'
import SocialMeta from '../components/socialMeta'
import DiagonalBlock from '../components/diagonalBlock'
import GetInTouchHome from '../components/getInTouchHome'
import CoverPage from '../components/coverPage'
import Img from '../components/responsiveCloudinary'
import HomeHeader from '../components/homeHeader'
import Intro from '../components/intro'
import Helmet from 'react-helmet'
// import scrollToComponent from 'react-scroll-to-component';
import { Element, scroller } from 'react-scroll'

import styles from '../assets/styles/pages/index.module.scss'
import { media } from '../utils/style-utils'
import styled from 'styled-components'
import GlobalComponents from '../components/globalcomponents'
import HomeVideoModule from '../components/HomeVideoModule'
import HomeBrands from '../components/HomeBrands'

if (typeof window !== `undefined`) {
  window.animationPlayed = false
}

const ScrollAnchor = styled.div`
  padding-top: 50px;
  display: none;

  @media (min-width: 768px) {
    display: block;
  }

  ${media.large`
    padding-top: 150px;
  `}
`

class IndexPage extends React.Component {
  constructor() {
    super()

    this.scrollDown = this.scrollDown.bind(this)
  }

  scrollDown() {
    // scrollToComponent(this.scrollAnchor, {
    //     align: 'top',
    //     duration: 1000,
    //     ease: 'inOutQuad',
    // });

    scroller.scrollTo('myScrollToElement', {
      duration: 1500,
      // delay: 100,
      smooth: true,
      //containerId: 'ContainerElementID',
      offset: 50, // Scrolls to element + 50 pixels down the page
    })
  }

  render() {
    return (
      <GlobalComponents>
        <Helmet>
          <title>Websites for what's next | Avenue</title>
        </Helmet>
        <SocialMeta
          twitterImage="https://res.cloudinary.com/avenue/image/upload/v1680571236/avenue-social-image_z6x4gt.png"
          ogImage="https://res.cloudinary.com/avenue/image/upload/v1680571236/avenue-social-image_z6x4gt.png"
          ogTitle="Websites for what’s next"
          ogURL="https://avenue.design/"
          ogDescription="We pair design and technology to create website experiences that drive leads, conversions and engagement."
        />
        <Intro />
        <HomeHeader scrollDown={this.scrollDown} />
        <HomeBrands />

        <Element name="myScrollToElement" />
        <ScrollAnchor
          ref={(scrollAnchor) => {
            this.scrollAnchor = scrollAnchor
          }}
        />
        <CoverPage />
        <HomeVideoModule
          title="What design means to us"
          subtitle="From chaos to clarity"
          video="https://vimeo.com/avenuedesign/chaos-to-clarity"
          light="https://res.cloudinary.com/avenue/image/upload/v1659662458/image_14_rzjjke.png"
        />

        <DiagonalBlock
          ctaAction="/about"
          ctaCopy="Get to know us"
          topRightCopy="Why Avenue?"
          centerCopy="Smart solutions fix real problems"
          bottomCopy="(And we love problem solving)"
        />
        <LinkBlocks>
          <LinkBlock
            title="What we do"
            image="HP/services_2x"
            cta="Find out more"
            action="/what-we-do"
            variation="services"
          >
            Building one-of-a-kind user experiences is our bread and butter. We
            marry strategy, design and technology to guarantee your brand the
            attention and results it deserves.
          </LinkBlock>
          <LinkBlock
            title="Avenue for Good"
            image="avenue-for-good_2x_bnp3kj"
            cta="Find out more"
            action="https://www.avenueforgood.com/"
            variation="services"
          >
            Time to give back. Our pro bono initiative supports small
            not-for-profit organisations, helping bring their vital missions to
            a wider audience in the digital space.
          </LinkBlock>
        </LinkBlocks>
        <GetInTouchHome
          variation="thick"
          left="But first, coffee."
          center="The best ideas start with great coffee. You pick the spot, we’ll pick up the tab."
          right="Get in touch"
          action="/contact"
        />
      </GlobalComponents>
    )
  }
}

const LinkBlocks = ({ children }) => {
  return (
    <section className="sectionSpacer">
      <div className="row">
        <div className="columns large-12">
          <div className="row">{children}</div>
        </div>
      </div>
    </section>
  )
}

const LinkBlock = ({ title, image, cta, action, children, variation }) => {
  return (
    <div className={`columns large-6`}>
      <div className={`${styles.linkBlock} ${variation && styles[variation]}`}>
        <Link
          to={action}
          style={{
            cursor: !action && 'auto',
          }}
        >
          <Img
            user="avenue"
            quality="100"
            large="500px"
            publicId={image}
            alt=""
          />

          <h3>{title}</h3>
          <p>{children}</p>
        </Link>
        <Link
          to={action}
          style={{
            cursor: !action && 'auto',
            color: !action && '#859599',
          }}
        >
          {cta}
        </Link>
      </div>
    </div>
  )
}

export default IndexPage
