import React from 'react'
import ReactDOM from 'react-dom'
import easeInQuad from 'bezier-easing'
import styles from './diagonalBlock.module.scss'
import styled from 'styled-components'
import { Link } from 'gatsby'

const easeInOut = easeInQuad(0.42, 0.0, 0.58, 1.0)
const ButtonLinkDefault = styled(Link)`
  color: #fff;
  font-size: 1.8rem;
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme['neon-pink']};
  padding: 22px 34px;
  display: inline-block;
  background: transparent;
  line-height: 2rem;
  font-family: ${(props) => props.theme.bodyFont};
  font-weight: 500;
  white-space: nowrap;
  transition: background 0.2s;

  @media (min-width: 1024px) {
    font-size: 2.4rem;
    padding: 24px 40px;
  }

  &:active,
  &:focus,
  &:hover {
    background: ${(props) => props.theme['purple-alt']};
    text-decoration: none;
    outline: none;
  }
`

const ButtonLinkHollow = styled(ButtonLinkDefault)`
  border-color: ${(props) => props.theme.purple};
  color: ${(props) => props.theme.purple};
  width: 100%;
  text-align: center;

  @media (min-width: 1024px) {
    width: initial;
  }

  &:active,
  &:focus,
  &:hover {
    color: #fff;
  }
`

const ButtonLinkDiagonalBlock = styled(ButtonLinkHollow)`
  @media (min-width: 1024px) {
    position: absolute;
    right: 0px;
    bottom: 0px;
    will-change: transform;
  }
`

class DiagonalBlock extends React.Component {
  constructor() {
    super()
    this.state = {
      line: {
        width: 500,
        height: 500,
        x1: 0,
        y1: 0,
        x2: 500,
        y2: 500,
      },
    }

    this.listener = this.handleScroll.bind(this)

    this.bottomRight = React.createRef()
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listener)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listener)
  }

  handleScroll(event) {
    let travel = 0
    let componentDims = ReactDOM.findDOMNode(this).getBoundingClientRect()
    let bottom = window.innerHeight * 0.75
    let top = window.innerHeight * 0
    let height = bottom - top
    let ratio = 1 - (componentDims.top - top) / height

    if (window.matchMedia('(min-width: 1024px)').matches) {
      travel = 200
    }

    if (window.matchMedia('(min-width: 1600px)').matches) {
      travel = 300
    }

    let pos = easeInOut(ratio) * travel

    if (componentDims.top > top && componentDims.top < bottom) {
      let offset = pos
      ReactDOM.findDOMNode(
        this.refs.topLeft
      ).style.transform = `translateX(${offset}px)`
      ReactDOM.findDOMNode(
        this.bottomRight.current
      ).style.transform = `translateX(-${offset}px)`
    }

    this.renderLine()
  }

  renderLine() {
    let { width, height, left, right } = ReactDOM.findDOMNode(
      this.refs.wrap
    ).getBoundingClientRect()
    let topLeft = ReactDOM.findDOMNode(
      this.refs.topLeft
    ).getBoundingClientRect()
    let bottomRight = ReactDOM.findDOMNode(
      this.bottomRight.current
    ).getBoundingClientRect()

    let line = {
      width,
      height,
      x1: topLeft.width / 2 + topLeft.left - left + 20,
      y1: topLeft.height + 10,
      x2: width - bottomRight.width / 2 + bottomRight.right - right + 20,
      y2: height - bottomRight.height - 10,
    }

    this.setState({
      line,
    })
  }

  render() {
    const { props } = this
    let settings = {}

    switch (props.type) {
      case 'testimonial':
        settings.columnClass = 'columns large-12'
        settings.copyClass = 'testimonialCopy'
        break
      default:
        settings.columnClass = 'columns large-10 large-push-1'
        settings.copyClass = 'centerCopy'
        break
    }

    return (
      <section className={`${styles.block} row`}>
        <div className={settings.columnClass}>
          <div ref="wrap">
            <span ref="topLeft" className={styles.topRight}>
              {props.topRightCopy}
            </span>
            <span className={`${styles[settings.copyClass]} ${props.type}`}>
              {props.type === 'testimonial' ? (
                <span className={styles.quote}>&ldquo;</span>
              ) : null}
              {props.centerCopy}
              {props.type === 'testimonial' ? (
                <span className={styles.quote}>&rdquo;</span>
              ) : null}
            </span>
            <span className={styles.bottomCopy}>{props.bottomCopy}</span>
            {props.type === 'testimonial' ? (
              <span ref={this.bottomRight} className={styles.bottomRight}>
                {props.ctaCopy}
              </span>
            ) : (
              <Link to={props.ctaAction}>
                <ButtonLinkDiagonalBlock
                  as="button"
                  ref={this.bottomRight}
                  type="diagonal-block"
                >
                  {props.ctaCopy}
                </ButtonLinkDiagonalBlock>
              </Link>
            )}

            <svg
              className={styles.backgroundAngle}
              height={this.state.line.height}
              width={this.state.line.width}
            >
              <line
                x1={this.state.line.x1}
                y1={this.state.line.y1}
                x2={this.state.line.x2}
                y2={this.state.line.y2}
                style={{ stroke: '#9D1864', strokeWidth: 1 }}
              />
            </svg>
          </div>
        </div>
      </section>
    )
  }
}

export default DiagonalBlock
