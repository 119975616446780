import React from 'react'

import VideoHome from '../components/VideoHome'
import styled from 'styled-components'
import { Link } from 'gatsby'

const ButtonLinkHeader = styled(Link)`
  color: #fff;
  font-size: 1.8rem;
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme['neon-pink']};
  padding: 22px 34px;
  display: inline-block;
  background: transparent;
  line-height: 2rem;
  font-family: ${(props) => props.theme.bodyFont};
  font-weight: 500;
  white-space: nowrap;
  transition: background 0.2s;

  @media (min-width: 1024px) {
    font-size: 2.4rem;
    padding: 24px 40px;
  }

  &:active,
  &:focus,
  &:hover {
    background: ${(props) => props.theme['purple-alt']};
    text-decoration: none;
    outline: none;
  }
`

const HomeHeaderSection = styled.section`
  @media (min-width: 768px) {
    background: #740344;
    position: relative;

    .bgAdjust & {
      background: #7c154e;
    }
    position: relative;
    overflow: hidden;
  }

  @media (min-width: 1024px) {
    visibility: ${(props) => (props.showHeader ? 'visible' : 'hidden')};
  }

  @media (min-width: 1440px) {
    height: calc(100vh - 160px);
  }

  @media (min-width: 1920px) {
    height: auto;
    min-height: 1080px;
  }

  @media (min-width: 1024px) and (max-height: 900px) {
    height: calc(100vh - 100px);
  }
`

const MastHead = styled.div`
  overflow: hidden;
  *zoom: 1;
  &:before,
  &:after {
    content: ' ';
    display: table;
  }
  &:after {
    clear: both;
  }
  position: relative;
  background: #740344;

  @media (min-width: 768px) {
    background: none;
  }

  @media (min-width: 1024px) {
    height: 100%;
    width: 100%;
  }
`

const HeaderCopy = styled.section`
  max-width: 100% !important;

  @media (min-width: 768px) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
  }
`

const WhiteWrap = styled.div`
  margin: 0 -20px;
  padding: 30px 30px;
  background: #fff;
  text-align: center;

  @media (min-width: 768px) {
    margin: 0;
    padding: 0;
    background: none;
    text-align: left;
  }

  @media (min-width: 1280px) {
    margin-left: -60px;
  }

  @media (min-width: 1440px) {
    margin-left: -30px;
  }

  p {
    color: ${(props) => props.theme.steel};
    font-size: 1.8rem;
    font-weight: 400;
    text-align: center;
    margin-bottom: 30px;

    @media (min-width: 768px) {
      text-align: left;

      max-width: 600px;
      color: #ffc5e6;
      margin: 0 0 40px 0;
      padding: 0;
      background: initial;
    }

    @media (min-width: 1024px) {
      font-size: 2rem;
      white-space: nowrap;
    }

    @media (min-width: 1280px) {
      font-size: 2.4rem;
    }
  }
`

const SubTitle = styled.span`
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    color: #e89bc7;
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 1.88px;
    position: relative;
    display: inline-block;
    margin-bottom: 15px;

    &:after {
      content: '';
      position: absolute;
      left: calc(100% + 14px);
      width: 80px;
      height: 1px;
      background: #f454af;
      top: 9px;
    }
  }

  @media (min-width: 1280px) {
    margin-bottom: 26px;

    &:after {
      width: 144px;
    }
  }
`

const BeBold = styled.h1`
  color: #202020;
  font-size: 4rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
  margin-top: 10px;
  text-align: center;
  margin-bottom: 20px;
  line-height: 1em;

  @media (min-width: 768px) {
    margin-top: 0;
    font-size: 60px;
    color: #fff;
    margin-bottom: 24px;
    margin-left: -5px;
    text-align: left;
  }

  @media (min-width: 1280px) {
    font-size: 80px;
  }
`

const AnchorLink = styled(ButtonLinkHeader)`
  display: none;

  @media (min-width: 768px) {
    display: inline;
  }

  &:focus {
    outline: none;
  }
`

class HomeHeader extends React.Component {
  _isMounted = false

  constructor() {
    super()

    this.state = {
      showHeader: false,
      scrolledDown: false,
    }

    this.ctaClicked = this.ctaClicked.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
    document.addEventListener('showHomeHeader', this.showHeader.bind(this))
  }

  showHeader() {
    if (this._isMounted) {
      this.setState({
        showHeader: true,
      })
    }
  }

  ctaClicked(e) {
    this.props.scrollDown()
  }

  componentWillUnmount() {
    this._isMounted = false
    document.removeEventListener('showHomeHeader', this.showHeader)
  }

  render() {
    return (
      <HomeHeaderSection showHeader={this.state.showHeader}>
        <MastHead>
          <VideoHome />
        </MastHead>

        <HeaderCopy>
          <div className="row">
            <div className="columns medium-6 medium-push-6 large-push-6 large-6 big-push-7 big-5">
              <WhiteWrap>
                {/* <SubTitle>Be Bold</SubTitle> */}
                <BeBold>
                  Websites for <br />
                  what's next
                </BeBold>
                <p>
                  We pair design and technology to create experiences
                  <br /> that drive conversions and engagement.
                </p>

                <AnchorLink as="button" onClick={this.ctaClicked}>
                  See how we do it
                </AnchorLink>
              </WhiteWrap>
            </div>
          </div>
        </HeaderCopy>
      </HomeHeaderSection>
    )
  }
}

export default HomeHeader
