import React from 'react'
import styled from 'styled-components'

const Video = styled.video`
  display: block;
  width: 195%;
  max-width: 195%;
  transition: opacity 300ms;
  object-fit: cover;
  height: 100%;

  @media (min-width: 768px) {
    max-width: 100%;
    width: 100%;
  }

  @media (min-width: 1920px) {
    width: 100%;
    height: 1080px;
    ${'' /* object-fit: none; */}
    float: left;
  }
`

class VideoHome extends React.Component {
  _isMounted = false

  constructor() {
    super()

    this.state = {
      videoPlaying: false,
      videoLoaded: false,
    }

    this.playVideo = this.playVideo.bind(this)
  }

  componentDidMount() {
    this._isMounted = true
    const video = document.querySelector('video')
    video.src =
      'https://res.cloudinary.com/avenue/video/upload/q_98/v1513565217/avenue-lights_bpx5gp.mp4'
    video.defaultPlaybackRate = 0.85
    video.load()
    video.pause()

    if (window.matchMedia('(max-width: 1023px)').matches) {
      video.play()
    }

    document.addEventListener('playVideo', this.playVideo)
  }

  componentWillUnmount() {
    this._isMounted = false
    document.removeEventListener('playVideo', this.playVideo)
  }

  playVideo() {
    if (this._isMounted) {
      const video = document.querySelector('video')
      video.play()
    }
  }

  render() {
    return (
      <Video
        muted
        playsInline
        poster="https://res.cloudinary.com/avenue/image/upload/q_80/v1524722367/avenue-hero-fallback_ekd2rw.jpg"
      />
    )
  }
}

export default VideoHome
