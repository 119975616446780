import React from 'react'
import styled from 'styled-components'
import bupa from '../assets/images/brands/bupa.png'
import vinomofo from '../assets/images/brands/vinomofo.png'
import coles from '../assets/images/brands/coles.png'
import canberra from '../assets/images/brands/canberra.png'
import target from '../assets/images/brands/target.png'
import keens from '../assets/images/brands/keens.png'

const Wrapper = styled.div`
  padding: 48px 0 36px;
  margin-bottom: 60px;

  @media (min-width: 768px) {
    margin-bottom: 0px;
    overflow: hidden;
  }
  background: #f3f7f9;
  h5 {
    text-transform: uppercase;
    font-family: $bodyFont;
    color: #b0c2c6;
    text-align: center;
    font-size: 15px;
    margin-bottom: 15px;
    letter-spacing: 1px;
  }
`

const ImagesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: 1440px) {
    margin-right: -40px;
    margin-bottom: -40px;
  }
  > * {
    width: 160px;

    @media (min-width: 1024px) {
      width: 180px;
    }
    @media (min-width: 1440px) {
      width: 205px;
      margin-right: 40px;
      margin-bottom: 40px;
    }
  }
`
const HomeBrands = () => {
  return (
    <Wrapper>
      <h5>Brands we have collaborated with</h5>

      <ImagesWrapper>
        <img src={bupa} />
        <img src={vinomofo} />
        <img src={coles} />
        <img src={canberra} />
        <img src={target} />
        <img src={keens} />
      </ImagesWrapper>
    </Wrapper>
  )
}

export default HomeBrands
