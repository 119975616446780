import React from 'react'
import { Link } from 'gatsby'
import Parallax from 'react-scroll-parallax/lib/components/Parallax'
import Image from './responsiveCloudinary'

import styles from './coverPage.module.scss'

class CoverPage extends React.Component {
  timeout

  constructor() {
    super()

    this.listener = this.handleScroll.bind(this)

    this.state = {
      line: {
        top: 0,
        length: 0,
        height: 0,
      },
    }
  }

  componentDidMount() {
    if (window.innerWidth > 1024) {
      window.addEventListener('scroll', this.listener)
      this.handleScroll()
      let componentDims = this.coverPage.getBoundingClientRect()

      document.querySelectorAll('#line')[0].style.top = `-${
        componentDims.height - 300
      }px`
      document.querySelectorAll('#line')[0].style.display = 'block'
    }
  }

  componentWillUnmount() {
    window.cancelAnimationFrame(this.timeout)
    window.removeEventListener('scroll', this.listener)
  }

  handleScroll(evt) {
    // If there's a timer, cancel it
    if (this.timeout) {
      window.cancelAnimationFrame(this.timeout)
    }

    // Setup the new requestAnimationFrame()
    this.timeout = window.requestAnimationFrame(
      function () {
        let componentDims = this.coverPage.getBoundingClientRect()
        let negtop = -componentDims.top
        let track = componentDims.height - window.innerHeight
        let height = 4200
        let length = (negtop / track) * height
        document.querySelectorAll('#line')[0].style.top = `-${height + 100}px`
        document.querySelectorAll(
          '#line'
        )[0].style.transform = `translate(0,${length}px)`
      }.bind(this)
    )
  }

  drawLine() {}

  render() {
    return (
      <section
        className={styles.block}
        ref={(coverPage) => {
          this.coverPage = coverPage
        }}
      >
        <div className="row">
          <div className="columns">
            <div className={styles.whiteBg}>
              <h2 className={styles.mainTitle}>Seeing is believing.</h2>
              <p className={styles.intro}>
                Building great user experiences is what gets us out of bed.
              </p>
            </div>
          </div>
        </div>

        <svg
          id="line"
          className={styles.backgroundLine}
          height={100}
          width={100}
        >
          <line
            x1="50%"
            y1={this.state.line.top}
            x2="50%"
            y2="100%"
            style={{ stroke: '#9D1864', strokeWidth: 1 }}
          />
        </svg>

        <CaseStudy
          caption="ANU REPORTER"
          title="Delivering immersive & accessible storytelling for Australia’s national university"
          slug="anu-reporter"
          img="ANU-Reporter-v2_w84fls"
          imgMax="700px"
          reverse={false}
          linktext="View case study"
        />
        <CaseStudy
          caption="Canberra.com.au"
          title="A digital gateway promoting the value of living, working and studying in Australia's capital"
          slug="canberra-com-au"
          img="Mask_Group_e7af5x"
          imgMax="700px"
          reverse={true}
          linktext="View case study"
        />

        <CaseStudy
          caption="Adventure+"
          title="Delivering product wow factor for a manufacturer that takes play seriously."
          slug="adventureplus"
          img="image_16_evrhbq"
          imgMax="700px"
          reverse={false}
          linktext="View case study"
        />
        <CaseStudy
          caption="Visit Ballarat"
          title="A campaign website that increased page views by 226% for Ballarat tourism"
          slug="ballarat"
          img="mob-feature-home_evuijr"
          imgMax="700px"
          reverse={true}
          linktext="View case study"
        />

        <CaseStudy
          caption="Vinomofo"
          title="Enhancing brand & user experience for one of Australia's largest eCommerce retailers."
          slug="vinomofo"
          img="image_17_blyfsw"
          imgMax="700px"
          reverse={false}
          linktext="View case study"
        />

        <CaseStudy
          caption="Imaging Associates"
          title="Communicating a radiology practice with a clear difference."
          slug="imaging-associates"
          img="Bitmap_geklw1"
          imgMax="700px"
          reverse={true}
          linktext="View case study"
        />
        <CaseStudy
          caption="Franco Crea"
          title="Letting the furniture do the talking for emerging Australian designer"
          slug="francocrea"
          img="home-case-study-franco_2x_h0chk7"
          imgMax="750px"
          reverse={false}
          linktext="View case study"
        />
        <CaseStudy
          caption="Sculptform"
          title="Elevating the sales experience for a leading Australian manufacturer"
          slug="sculptform"
          img="sculptform-homepage-teaser_2x_owxp4m"
          imgMax="700px"
          imgClass={styles.image4}
          reverse={true}
          linktext="View case study"
        />

        {/* <CaseStudy
          caption="Building better city websites"
          title="A real-time data insights application on customer digital activity and engagement"
          slug="opencities"
          img="home-case-study-opencities_2x_pupqph"
          imgClass={styles.image2}
          imgMax="578px"
          reverse={true}
          linktext="View case study"
        /> */}

        {/* <CaseStudy
          caption="Designed for a great cause"
          title="A web app that empowers workplace-based giving initiatives"
          slug="1-million-donors"
          img="HP/home-case-study-1MD_2x"
          imgClass=""
          imgMax="680px"
          reverse={false}
          linktext="View case study"
        /> */}

        {/* <CaseStudy
          caption="Let the games begin"
          title="A dashboard designed to support 20 million gamer’s worth of data per month"
          slug="zam"
          img="HP/home-case-study-zam_2x"
          imgClass={styles.image3}
          imgMax="650px"
          reverse={true}
          linktext="View case study"
        /> */}

        <div className="row">
          <div className={`${styles.more} columns large-12`} />
        </div>
      </section>
    )
  }
}

const CaseStudy = ({
  img,
  imgClass,
  title,
  caption,
  slug,
  reverse,
  linktext,
  imgMax,
}) => {
  return (
    <div className={`row ${styles.row}`}>
      <div className={`column large-6 ${reverse ? 'large-push-6' : ''}`}>
        {/* <Image user="avenue" publicId={img} className={imgClass} large={imgMax} /> */}
        <Link
          to={slug ? `/work/${slug}` : null}
          style={{
            cursor: !slug && 'auto',
          }}
        >
          <Parallax
            offsetYMax="30%"
            offsetYMin="-20%"
            tag="figure"
            styleOuter={{ margin: 0 }}
          >
            <Image
              user="avenue"
              publicId={img}
              className={imgClass}
              large={imgMax}
              alt=""
            />
          </Parallax>
        </Link>
      </div>
      <div
        className={`column large-6 ${styles.column} ${
          reverse ? 'large-pull-6' : ''
        }`}
      >
        <div className={styles.textWrap}>
          <span>{caption}</span>
          <h3 className={styles.caseStudyTitle}>{title}</h3>
          <Link
            to={slug ? `/work/${slug}` : null}
            style={{
              textDecoration: !slug && 'none',
              cursor: !slug && 'auto',
              color: !slug && '#859599',
            }}
          >
            {linktext}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default CoverPage
